/* Button arrow */
.button--arrow {
  font-size: 20.25px;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 0.75rem 2rem;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.button--arrow,
.button--arrow span {
  position: relative;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.button--arrow span {
  display: block;
  line-height: 1.2;
  pointer-events: none;
}

.button--arrow:after,
.button--arrow:before {
  background: #000;
  content: "";
  height: 18px;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 4px;
}

.button--arrow:before {
  bottom: 50%;
  top: auto;
}

.button--arrow:hover,
.card:hover .button--arrow {
  background: #f7fd09;
}

.button--arrow:hover span,
.card:hover .button--arrow span {
  -webkit-transform: translateX(-0.5rem);
  -ms-transform: translateX(-0.5rem);
  transform: translateX(-0.5rem);
}

.button--arrow:hover:before,
.card:hover .button--arrow:before {
  -webkit-transform: translate(-1rem, 3px) rotate(-45deg);
  -ms-transform: translate(-1rem, 3px) rotate(-45deg);
  transform: translate(-1rem, 3px) rotate(-45deg);
}

.button--arrow:hover:after,
.card:hover .button--arrow:after {
  -webkit-transform: translate(-1rem, -3px) rotate(45deg);
  -ms-transform: translate(-1rem, -3px) rotate(45deg);
  transform: translate(-1rem, -3px) rotate(45deg);
}

.card--headline-teaser {
  position: relative;
}

@media (min-width: 992px) {
  .card--headline-teaser {
    height: 364px;
  }
}

/* Card */

.card__caption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.card__caption header {
  width: 100%;
}

.card:hover .card__picture:before {
  opacity: 1;
}

.card:hover .card__image {
  -webkit-transform: scale(1.025);
  -ms-transform: scale(1.025);
  transform: scale(1.025);
}

.card__picture {
  display: block;
  overflow: hidden;
  position: relative;
}

.card__picture:before {
  background: -o-linear-gradient(130deg, #000 0, #000 100%);
  background: linear-gradient(320deg, #000, #000);
  background: -o-linear-gradient(
    130deg,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(320deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 100%;
  z-index: 1;
}

.card--30-70 .card__caption,
.card--70-30 .card__caption {
  -ms-flex-line-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-content: center;
  align-items: center;
  padding: 1.5rem;
}

@media (min-width: 992px) {
  .card--30-70 .card__caption,
  .card--70-30 .card__caption {
    height: 364px;
    padding: 4rem 2rem;
  }
}

.card--50-50 .card__caption {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-flex-line-pack: center;
  align-content: center;
  align-items: center;
  padding: 1.5rem;
  text-align: left;
}

@media (min-width: 992px) {
  .card--50-50 .card__caption {
    -ms-flex-line-pack: center;
    align-content: center;
    height: 364px;
    padding: 4rem 2rem;
  }
}

.card--square {
  position: relative;
}

@media (min-width: 992px) {
  .card--square {
    height: 364px;
  }
}

.card--headline-teaser .card__caption {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
}

.card--headline-teaser .card__caption,
.card--square .card__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
}

.card--square .card__inner {
  height: 100%;
}

.card--square .card__caption {
  padding: 1.5rem;
}

@media (min-width: 992px) {
  .card--square .card__caption {
    left: 0;
    padding: 0 2rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
  }
}

.card--icon-teaser {
  position: relative;
  text-decoration: none;
  width: 100%;
}

.card--icon-teaser .card__caption {
  -ms-flex-line-pack: center;
  align-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.5rem;
  width: 100%;
}

@media (min-width: 992px) {
  .card--icon-teaser .card__caption {
    height: 364px;
    padding: 2rem 2rem 2rem 40%;
  }

  .card--aligned-right .card__caption {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .card--aligned-right * {
    text-align: right;
  }
}

.card--30-70 .card__inner,
.card--50-50 .card__inner,
.card--70-30 .card__inner {
  display: grid;
  font-size: 36px;
  font-size: 2rem;
}

.card--30-70 .card__picture,
.card--50-50 .card__picture {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

@media (min-width: 992px) {
  .card--30-70 .card__picture,
  .card--50-50 .card__picture {
    -webkit-box-ordinal-group: inherit;
    -ms-flex-order: inherit;
    order: inherit;
  }
}

.card--30-70 .card__caption,
.card--50-50 .card__caption {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

@media (min-width: 992px) {
  .card--30-70 .card__caption,
  .card--50-50 .card__caption {
    -webkit-box-ordinal-group: inherit;
    -ms-flex-order: inherit;
    order: inherit;
  }
}

.card--30-70 .card__inner,
.card--50-50 .card__inner,
.card--70-30 .card__inner,
.card--headline-teaser .card__inner,
.card--icon-teaser .card__inner {
  text-decoration: none;
}

.card--50-50 .card__inner {
  grid-template-columns: 100%;
}

@media (min-width: 768px) {
  .card--50-50 .card__inner {
    grid-template-columns: 50% 50%;
  }
}

.card--headline-teaser .card__inner {
  display: grid;
  grid-template-columns: 100%;
}

@media (min-width: 992px) {
  .card--headline-teaser .card__inner {
    grid-template-columns: 100%;
  }
}

.col--3-6 .card--headline-teaser .card__inner {
  grid-template-rows: 100%;
}

@media (min-width: 992px) {
  .col--3-6 .card--headline-teaser .card__inner {
    grid-template-columns: 50% 50%;
  }
}

.card__image {
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 100%;
}

@media (min-width: 768px) {
  .card--30-70 .card__inner {
    grid-template-columns: 50% 50%;
  }
}

@media (min-width: 992px) {
  .card--30-70 .card__inner {
    grid-template-columns: calc(33.33333% - 1.33333rem) calc(
        66.66667% + 1.33333rem
      );
  }
}

@media (min-width: 768px) {
  .card--70-30 .card__inner {
    grid-template-columns: 50% 50%;
  }
}

@media (min-width: 992px) {
  .card--70-30 .card__inner {
    grid-template-columns: 68.93% 31.07%;
  }
}

.card--30-70 .card__picture,
.card--50-50 .card__picture,
.card--70-30 .card__picture,
.card--icon-teaser .card__picture {
  width: 100%;
}

@media (min-width: 992px) {
  .card--30-70 .card__picture,
  .card--50-50 .card__picture,
  .card--70-30 .card__picture,
  .card--icon-teaser .card__picture {
    min-height: 240px;
  }
}

.card--headline-teaser .card__picture {
  display: block;
  position: relative;
}

.card--30-70 .card__image,
.card--50-50 .card__image,
.card--70-30 .card__image,
.card--headline-teaser .card__image,
.card--icon-teaser .card__image {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.card--icon-teaser .card__picture {
  display: block;
  overflow: hidden;
}

@media (min-width: 992px) {
  .card--icon-teaser .card__picture {
    background: #000;
    background: rgba(0, 0, 0, 0);
    height: 100%;
    left: 0;
    -o-object-fit: inherit;
    object-fit: inherit;
    position: absolute;
    top: 0;
    width: calc(40% - 1rem);
  }
}

.card--icon-teaser .card__image {
  background: #f3f3f3;
  height: 200px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  z-index: 1;
}

@media (min-width: 992px) {
  .card--icon-teaser .card__image {
    background: #000;
    background: rgba(0, 0, 0, 0);
    height: 100%;
    left: 0;
    -o-object-fit: inherit;
    object-fit: inherit;
    position: absolute;
    top: 0;
  }
}

.card--30-70 .card__caption,
.card--50-50 .card__caption,
.card--70-30 .card__caption,
.card--headline-teaser .card__caption,
.card--icon-teaser .card__caption {
  position: relative;
}

.card--30-70 .card__caption,
.card--50-50 .card__caption,
.card--70-30 .card__caption,
.card--headline-teaser .card__caption,
.card--icon-teaser .card__caption,
.card--square .card__caption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.card--30-70 .card__caption:after,
.card--30-70 .card__caption:before,
.card--50-50 .card__caption:after,
.card--50-50 .card__caption:before,
.card--70-30 .card__caption:after,
.card--70-30 .card__caption:before,
.card--headline-teaser .card__caption:after,
.card--headline-teaser .card__caption:before,
.card--icon-teaser .card__caption:after,
.card--icon-teaser .card__caption:before,
.card--square .card__caption:after,
.card--square .card__caption:before {
  background: #fff;
  content: "";
  height: 18px;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 4px;
  z-index: 2;
}

.card--30-70 .card__caption.u-background--bright:after,
.card--30-70 .card__caption.u-background--bright:before,
.card--30-70 .card__caption.u-background--grey-bright:after,
.card--30-70 .card__caption.u-background--grey-bright:before,
.card--50-50 .card__caption.u-background--bright:after,
.card--50-50 .card__caption.u-background--bright:before,
.card--50-50 .card__caption.u-background--grey-bright:after,
.card--50-50 .card__caption.u-background--grey-bright:before,
.card--70-30 .card__caption.u-background--bright:after,
.card--70-30 .card__caption.u-background--bright:before,
.card--70-30 .card__caption.u-background--grey-bright:after,
.card--70-30 .card__caption.u-background--grey-bright:before,
.card--headline-teaser .card__caption.u-background--bright:after,
.card--headline-teaser .card__caption.u-background--bright:before,
.card--headline-teaser .card__caption.u-background--grey-bright:after,
.card--headline-teaser .card__caption.u-background--grey-bright:before,
.card--icon-teaser .card__caption.u-background--bright:after,
.card--icon-teaser .card__caption.u-background--bright:before,
.card--icon-teaser .card__caption.u-background--grey-bright:after,
.card--icon-teaser .card__caption.u-background--grey-bright:before,
.card--square .card__caption.u-background--bright:after,
.card--square .card__caption.u-background--bright:before,
.card--square .card__caption.u-background--grey-bright:after,
.card--square .card__caption.u-background--grey-bright:before {
  background: #000;
}

.card--30-70 .card__caption:before,
.card--50-50 .card__caption:before,
.card--70-30 .card__caption:before,
.card--headline-teaser .card__caption:before,
.card--icon-teaser .card__caption:before,
.card--square .card__caption:before {
  bottom: 50%;
  top: auto;
}

.card:hover .card__caption span {
  -webkit-transform: translateX(-0.5rem);
  -ms-transform: translateX(-0.5rem);
  transform: translateX(-0.5rem);
}

.card:hover .card__caption:before {
  -webkit-transform: translate(-1rem, 3px) rotate(-45deg);
  -ms-transform: translate(-1rem, 3px) rotate(-45deg);
  transform: translate(-1rem, 3px) rotate(-45deg);
}

.card:hover .card__caption:after {
  -webkit-transform: translate(-1rem, -3px) rotate(45deg);
  -ms-transform: translate(-1rem, -3px) rotate(45deg);
  transform: translate(-1rem, -3px) rotate(45deg);
}

.card + .card {
  margin-top: 2rem;
}

@media (min-width: 992px) {
  .card + .card {
    margin-top: 0;
  }
}

.card--headline-teaser .card__caption {
  padding: 1.5rem;
}

@media (min-width: 992px) {
  .card--headline-teaser .card__caption {
    padding: 1rem 2rem;
  }

  .card--headline-teaser .card__caption,
  .card--headline-teaser .card__picture {
    height: 182px;
    min-height: auto;
  }
}

.col--3-6 .card--headline-teaser .card__caption,
.col--3-6 .card--headline-teaser .card__picture {
  width: 100%;
}

@media (min-width: 992px) {
  .col--3-6 .card--headline-teaser .card__caption,
  .col--3-6 .card--headline-teaser .card__picture {
    height: 364px;
  }
}

/* General */

.icon {
  speak: never;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: daimler_iconset;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

a .icon.customIcon .hover {
  display: none;
}

a:hover .customIcon .hover {
  display: block;
}

a:hover .customIcon .normal {
  display: none;
}

.title--bold {
  font-weight: 700;
}

.title--uppercase {
  text-transform: uppercase;
}

.subtitle {
  font-size: 22.5px;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.introduction {
  font-size: 20.25px;
  font-size: 1.125rem;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .introduction {
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .introduction {
    font-size: 1.4rem;
  }
}

/* .introduction + * {
    margin-top: 1.5rem
} */

.link {
  text-decoration: none;
}

.link--dark {
  color: #000;
}

.u-background--grey-dark {
  background: #707070;
}

.u-background--grey-dark * {
  color: #fff;
}

.u-background--grey-medium {
  background: #c8c8c8;
}

.u-background--grey-medium * {
  color: #fff;
}

.u-background--grey-bright {
  background: #e6e6e6;
}

.u-background--grey-bright * {
  color: #000;
}

.u-background--dark {
  background: #000;
}

.u-background--dark * {
  color: #fff;
}

.u-background--bright {
  background: #fff;
}

.u-background--bright * {
  color: #000;
}

.u-color--grey-dark {
  color: #707070;
}

.u-color-dark {
  color: #000;
}

.u-color--bright {
  color: #fff;
}

/* FORM MULTISELECT (USADO EN LOS FILTROS DEL TITULO DE PÁGINA) */
.form-multiselect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  z-index: 10;
}

.form-multiselect--open {
  z-index: 20;
}

.form-multiselect__options {
  background: #fff;
  left: 0;
  list-style: none;
  max-height: 300px;
  overflow-y: scroll;
  position: absolute;
  top: calc(100% + 6px);
  width: 100%;
  border-top: 1px solid #e6e6e6;
  -webkit-box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
}

.form-multiselect__options:empty {
  border-top: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-multiselect__input {
  background: #fff;
  border: 0;
  display: block;
  font-family: "DaimlerCS", sans-serif;
  font-size: 18px;
  /* font-size: 1rem; */
  font-weight: 700;
  padding: 0.5rem 2rem 0.5rem 1rem;
  width: 100%;
  line-height: 1rem;
}

/* Fix para controles fuera del área de filtros */
.inputWrapper .form-multiselect__input {
  font-weight: 400 !important;
  font-family: DaimlerCS-Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
}

.inputWrapper .form-multiselect__input:focus {
  border: 1px solid #00677f;
}
.module .inputWrapper .form-multiselect__close {
  right: 5px !important;
  bottom: 2px !important;
  top: auto !important;
}

/* .module .inputWrapper .form-multiselect__input {
  padding-right: 32px !important;
  text-overflow: ellipsis !important;
} */

.inputWrapper .form-multiselect__options {
  top: 100%;
}

.form-multiselect__input::placeholder {
  font-weight: 300;
  color: #000;
}

.form-multiselect__read--only {
  border: 0;
  display: block;
  font-family: "DaimlerCS", sans-serif;
  font-size: 18px;
  font-weight: 700;
  padding: 0.5rem 0;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-multiselect__input--empty {
  font-weight: 300;
}

.form-multiselect__option {
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 18px;
}

.form-multiselect__option:hover {
  background-color: var(--wb-grey-90);
}

.form-multiselect__option + .form-multiselect__option {
  border-top: 1px solid #e6e6e6;
}

.form-multiselect__option--small {
  font-size: 13.5px;
  font-size: 0.75rem;
}

.form-multiselect__input-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.form-multiselect__input-wrap .button {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  padding: 0;
  width: 54px;
  width: 3rem;
}

.form-multiselect__input-wrap .button .icon {
  margin: 0;
}

.form-multiselect__close {
  cursor: pointer;
  display: block;
  height: 18px;
  height: 1rem;
  position: absolute;
  right: 10px;
  /* right: 3rem; */
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 18px;
  width: 1rem;
}

.form-multiselect__close:before {
  content: "+";
  display: block;
  font-size: 32px;
  /* font-size: 2rem; */
  left: 50%;
  line-height: 18px;
  line-height: 1rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.form-multiselect__placeholder {
  font-weight: 300;
}

/* BUTTON */
.button {
  background: #fff;
  border: none;
  color: #000;
  display: inline-block;
  font-family: DaimlerCS, sans-serif;
  font-size: 15.75px;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 18px;
  line-height: 1rem;
  padding: 0.5rem 1.25rem 0.625rem;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.button .icon {
  margin-left: -0.25rem;
  margin-right: 0.5rem;
}

.button--dark {
  background: #000;
  color: #fff;
}

.button--dark:hover {
  /* background: #333; */
  background-color: var(--blue-1);
  cursor: pointer;
}

.button--dark .icon {
  color: #fff;
}

.button--bold {
  font-weight: 700;
}

.button--outlined {
  border: 2px solid #000;
  color: #000;
}

.button--outlined:hover {
  border-color: #707070;
  cursor: pointer;
}

.button--grey-light {
  background: #c8c8c8;
  color: #000;
}

.button--grey-light:hover {
  background: #333;
  cursor: pointer;
}

.button--warning {
  background: #fff;
  color: #a61f1f;
}

.button--warning:hover {
  color: #de4d4d;
  cursor: pointer;
}

.button--warning + .button--rounded {
  margin-left: 0.5rem;
}

.button--arrow {
  font-size: 20.25px;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 0.75rem 2rem;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.button--arrow,
.button--arrow span {
  position: relative;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.button--arrow span {
  display: block;
  line-height: 1.2;
  pointer-events: none;
}

.button--arrow:after,
.button--arrow:before {
  background: #000;
  content: "";
  height: 18px;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 4px;
}

.button--arrow:before {
  bottom: 50%;
  top: auto;
}

.button--arrow:hover,
.card:hover .button--arrow {
  background: #f7fd09;
}

.button--arrow:hover span,
.card:hover .button--arrow span {
  -webkit-transform: translateX(-0.5rem);
  -ms-transform: translateX(-0.5rem);
  transform: translateX(-0.5rem);
}

.button--arrow:hover:before,
.card:hover .button--arrow:before {
  -webkit-transform: translate(-1rem, 3px) rotate(-45deg);
  -ms-transform: translate(-1rem, 3px) rotate(-45deg);
  transform: translate(-1rem, 3px) rotate(-45deg);
}

.button--arrow:hover:after,
.card:hover .button--arrow:after {
  -webkit-transform: translate(-1rem, -3px) rotate(45deg);
  -ms-transform: translate(-1rem, -3px) rotate(45deg);
  transform: translate(-1rem, -3px) rotate(45deg);
}

.button--rounded {
  border-radius: 0.75rem;
}

.button--outlined + .button--outlined {
  margin-left: 1rem;
}
