/* INPUTS ================================================= */
.inputWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.inputWrapper .rango {
  margin-top: 26px;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.inputWrapper .rango .note {
  font-weight: 500;
  color: #666;
  font-size: 15px;
  line-height: 1.3em;
}

.inputWrapper mat-label {
  position: absolute;
  display: block;
  pointer-events: none;
  left: 10px;
  margin-left: 0;
  top: 36px;
  top: 0;
  transition: 0.3s ease-out all;
  font-size: 19px;
  font-weight: 500;
  color: #666666;
  width: 300px;
  transform-origin: 0 0;
}

.inputWrapper input {
  width: 100%;
  border: 1px solid #c8c8c8;
  /* background: transparent; */
  background: #fff;
  font-size: 18px;
  width: 100%;
  margin-top: 24px;
  left: 0;
  opacity: 1;
  padding: 10px 10px 10px 10px;
}

.size1 {
  width: 65px !important;
}

.size2 {
  width: 130px !important;
}

.inputWrapper .alert {
  font-size: 15px;
  line-height: 1.2em !important;
  color: #aa0000;
  margin-left: 10px;
}

.inputWrapper input.error {
  border: 1px solid #ff0000;
}

.inputWrapper input[type="text"]:focus,
.inputWrapper input[type="email"]:focus,
.inputWrapper input[type="number"]:focus,
.inputWrapper input[type="password"]:focus {
  border: 1px solid #00677f;
}

.inputWrapper input[type="text"]:disabled,
.inputWrapper input[type="email"]:disabled,
.inputWrapper input[type="number"]:disabled,
.inputWrapper input[type="password"]:disabled {
  background-color: transparent;
  border: 1px solid transparent;
  color: #000;
}

.inputWrapper .floatingLabel,
.selectWrapper .floatingLabel,
.attachWrapper .floatingLabel {
  position: absolute;
  display: block;
  pointer-events: none;
  left: 10px;
  margin-left: 0;
  top: 36px;
  /* Deshabilita flotación */
  top: 0;
  transition: 0.3s ease-out all;
  font-size: 19px;
  font-weight: 500;
  color: #666666;
  width: 300px;
  transform-origin: 0 0;
}

.inputWrapper label:not(.checkboxWrapper) {
  position: absolute;
  display: block;
  pointer-events: none;
  left: 10px;
  margin-left: 0;
  top: 36px;
  top: 0;
  transition: 0.3s ease-out all;
  font-size: 19px;
  font-weight: 500;
  color: #666666;
  width: 300px;
  transform-origin: 0 0;
}

.inputWrapper .floatingLabel span.required,
.selectWrapper .floatingLabel span.required {
  color: #ff0000;
}

/* Control de posición de etiquetas flotantes */
.inputWrapper input:focus ~ .floatingLabel {
  top: 0;
}

.inputWrapper input:not(:placeholder-shown) ~ .floatingLabel {
  top: 0;
}

.selectWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.selectWrapper label {
  font-size: 19px;
  font-weight: 500;
  color: #666666;
  width: 300px;
  margin-left: 10px;
}

.selectWrapper label:not(.checkboxWrapper) {
  position: absolute;
  display: block;
  pointer-events: none;
  left: 10px;
  margin-left: 0;
  top: 36px;
  top: 0;
  transition: 0.3s ease-out all;
  transform-origin: 0 0;
}

.selectWrapper label span.required {
  color: #ff0000;
}

.selectWrapper .customSelect {
  position: relative;
  cursor: pointer;
  width: 100%;
  border: 1px solid #c8c8c8;
  background: #fff;
  font-size: 18px;
  width: 100%;
  margin-top: 24px;
  left: 0;
  opacity: 1;
  height: 44px;
}

.selectWrapper .customSelect select {
  padding: 10px 26px 10px 8px;
  font-size: 18px;
  position: relative;
  z-index: 1;
  background: transparent;
  border: none;
  width: 100%;
  font-family: DaimlerCS-Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
}

.selectWrapper .customSelect:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 6px;
  background-color: #000;
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  right: 12px;
  top: 18px;
  z-index: 0;
}

.controlWrapper .customSelect {
  position: relative;
  cursor: pointer;
  width: 100%;
  border: 1px solid #c8c8c8;
  background: #fff;
  font-size: 18px;
  width: 100%;
  height: 44px;
  transform: translateY(0px); /* Prevent any shifting */
  line-height: 21px;
}

.controlWrapper .customSelect select {
  padding: 10px 26px 10px 10px;
  font-size: 18px;
  position: relative;
  z-index: 1;
  background: transparent;
  border: none;
  width: 100%;
  font-family: DaimlerCS-Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
  line-height: 21px;
}

.controlWrapper .customSelect select:active {
  transform: translateY(0px); /* Prevent any shifting */
}

.controlWrapper .customSelect:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 6px;
  background-color: #000;
  -webkit-clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  right: 12px;
  top: 18px;
  z-index: 0;
  transform: translateY(0px); /* Prevent any shifting */
  line-height: 21px;
}

.controlWrapper .customSelect select:focus {
  outline: none; /* Elimina el borde de enfoque predeterminado */
}

.inputWrapper mat-select,
.inputWrapper mat-autocomplete {
  margin-top: 24px;
}

app-input-search {
  width: 100%;
}

.modal label {
  display: block;
  font-size: 19px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 1px;
}

/* INPUTS ================================================= */
.controlWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.controlWrapper input {
  width: 100%;
  border: 1px solid #c8c8c8;
  background: #fff;
  font-size: 18px;
  width: 100%;
  margin-top: 0;
  padding: 10px 10px 10px 10px;
}

.controlWrapper span.readOnly {
  width: 100%;
  border: 1px solid #fff;
  background: #fff;
  display: block;
  font-size: 18px;
  line-height: 1rem;
  width: 100%;
  margin-top: 0;
  padding: 10px 10px 10px 10px;
}

.controlWrapper input[type="text"]:focus,
.controlWrapper input[type="email"]:focus,
.controlWrapper input[type="number"]:focus,
.controlWrapper input[type="password"]:focus {
  border: 1px solid #00677f;
}

.controlWrapper input[type="text"]:disabled,
.controlWrapper input[type="email"]:disabled,
.controlWrapper input[type="number"]:disabled,
.controlWrapper input[type="password"]:disabled {
  background-color: transparent;
  border: 1px solid transparent;
  color: #000;
}

.controlWrapper label {
  display: block;
  pointer-events: none;
  margin-left: 11px;
  margin-top: 1px;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
  color: #666666;
  height: 21px;
}

.controlWrapper label span.required {
  color: #ff0000;
}

/* FIX Para multiselect en controlWrapper */
.controlWrapper .form-multiselect__input {
  border: 1px solid #c8c8c8;
  border-right: none;
  background: #fff;
  font-size: 18px;
  width: 100%;
  left: 0;
  opacity: 1;
  padding: 10px;
  font-family: DaimlerCS-Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
  line-height: 1rem;
  font-weight: 400;
}

.controlWrapper .form-multiselect__input .form-multiselect__placeholder {
  color: #666;
}

.controlWrapper .form-multiselect__options {
  box-shadow: none;
  border-top: none;
  border-right: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
  top: 100%;
}

.controlWrapper .form-multiselect__option {
  padding: 10px;
}

.controlWrapper .form-multiselect__input-wrap .button {
  /* background-color: yellow; */
  background: transparent;
  border-top: 1px solid #c8c8c8;
  border-right: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  width: 26px;
}
.controlWrapper .form-multiselect__input-wrap .button i {
  display: none;
}
.controlWrapper .form-multiselect__input-wrap .button::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 6px;
  background-color: rgb(0, 0, 0);
  clip-path: polygon(100% 0%, 0px 0%, 50% 100%);
  right: 12px;
  top: 18px;
  z-index: 0;
  transform: translateY(0px);
  line-height: 21px;
}

.controlWrapper .form-multiselect__close {
  display: none !important;
}

/* TEXTAREA ================================================= */
.textareaWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.textareaWrapper label {
  position: absolute;
  display: block;
  pointer-events: none;
  left: 10px;
  margin-left: 0;
  top: 36px;
  /* Deshabilita flotación */
  top: 0;
  transition: 0.3s ease-out all;
  font-size: 19px;
  font-weight: 500;
  color: #666666;
  width: 300px;
  transform-origin: 0 0;
}

.textareaWrapper textarea {
  width: 100%;
  border: 1px solid #c8c8c8;
  background: #fff;
  font-size: 18px;
  width: 100%;
  margin-top: 24px;
  left: 0;
  opacity: 1;
  padding: 10px 10px 10px 10px;
}

/* ATTACHMENTS */
.attachWrapper {
  margin-bottom: 10px;
}

.attachWrapper > label:first-child {
  margin-left: 10px;
}

.attachWrapper .attachComponent {
  display: flex;
  width: 100%;
  border: 1px solid #c8c8c8;
  background: #fff;
  font-size: 18px;
  width: 100%;
  padding: 10px 10px 10px 10px;
  min-height: 80px;
  justify-content: center;
  align-items: center;
}

.attachWrapper
  .mdc-text-field--focused:not(.mdc-text-field--disabled)
  .mdc-floating-label {
  color: #666;
}

.attachWrapper .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  padding-left: 16px;
  border-radius: 0;
}

.attachWrapper
  .mat-mdc-form-field-has-icon-prefix
  .mat-mdc-text-field-wrapper
  button {
  border: none;
  background-color: transparent !important;
  padding: 0;
}

.attachWrapper .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  border: 1px solid #c8c8c8 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.attachWrapper
  .mdc-text-field--filled:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused {
  border: 1px solid var(--blue-1) !important;
}

.attachWrapper
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
  border-bottom: none !important;
}

.attachWrapper
  .mdc-text-field--filled:not(.mdc-text-field--disabled):hover
  .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
  border-bottom: none !important;
}

.attachWrapper .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-family: "DaimlerCS-Regular";
  letter-spacing: 0;
}

.attachWrapper .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.attachWrapper .mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.attachWrapper .mdc-text-field--filled .mdc-line-ripple:after,
.attachWrapper .mdc-text-field--filled .mdc-line-ripple:after {
  display: none;
}

.recipientsWrapper .tagsComponent {
  /* display: flex;
    width: 100%;
    border: 1px solid #C8C8C8;
    background: #FFF;
    font-size: 18px;
    width: 100%;
    padding: 10px 10px 10px 10px; */
  min-height: 60px;
  /* justify-content: center;
    align-items: center; */
}

/* TAGS */
.tagsComponent > label:first-child {
  margin-left: 10px;
}

.tagsComponent .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  padding-left: 16px;
  border-radius: 0;
}

.tagsComponent .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
  border: 1px solid #c8c8c8 !important;
  border-radius: 0;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.tagsComponent
  .mdc-text-field--filled:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused {
  border: 1px solid var(--blue-1) !important;
}

.tagsComponent
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
  border-bottom: none !important;
}

.tagsComponent
  .mdc-text-field--filled:not(.mdc-text-field--disabled):hover
  .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
  border-bottom: none !important;
}

.tagsComponent .mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  font-family: "DaimlerCS-Bold";
  letter-spacing: 0;
}

.tagsComponent .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.tagsComponent .mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.tagsComponent .mdc-text-field--filled .mdc-line-ripple:after,
.tagsComponent .mdc-text-field--filled .mdc-line-ripple:after {
  display: none;
}

.tagsComponent
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding: 8px 0 !important;
}

/* ETIQUETAS */
.tag {
  padding: 0.1em 0.4em 0.2em;
  font-family: DaimlerCS-Bold;
  font-size: 15px;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  display: flex;
  border: 1px solid #808080;
  background: #f4f4f4;
  color: #808080;
}

.tag.borrador {
  border: 1px solid #808080;
  background: #f4f4f4;
  color: #808080;
}
.tag.enAprobacion {
  border: 1px solid #e69123;
  background: #fdf6ed;
  color: #e69123;
}
.tag.aprobado {
  border: 1px solid #6ea046;
  background: #f3f7f0;
  color: #6ea046;
}
.tag.rechazado {
  border: 1px solid #ff5959;
  background: #ffeaea;
  color: #ff5959;
}
.tag.descontinuado {
  border: 1px solid #6baec9;
  background: #f2f8fa;
  color: #6baec9;
}

/* CHECKBOX ================================================= */
/* Custom checkboxes */
.checkboxWrapper {
  position: relative;
  margin-bottom: 0;
  /* height: 24px; */
  display: flex;
}

/* Hide the browser's default checkbox */
.checkboxWrapper .inputWrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 2px solid #707070;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 30px;
}

input:disabled ~ .checkmark,
input:disabled ~ .checkmark:hover {
  border: 2px solid #c8c8c8;
}

.checkmark span {
  display: none;
}

/* On mouse-over */
.checkboxWrapper .checkmark:hover {
  background-color: #fff;
  border: 2px solid #707070;
}

/* When the checkbox */
.checkboxWrapper input:checked ~ .checkmark,
.checkboxWrapper input:checked ~ .checkmark:hover {
  background-color: #007a92;
  background-image: url("../images/icons/checkmark.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  border: 2px solid #007a92;
}

.checkboxWrapper input:checked:disabled ~ .checkmark,
.checkboxWrapper input:checked:disabled ~ .checkmark:hover {
  background-color: #adc9d7;
  background-image: url("../images/icons/checkmark.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  border: 2px solid #adc9d7;
}

.checkboxWrapper:hover input:checked ~ .checkmark {
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxWrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxWrapper .checkmark:after {
  /* background-image: url("/assets/images/icons/check.svg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 24px 24px;
    width: 24px;
    height: 24px; */
}

.checkboxWrapper .description {
  padding-left: 24px;
  padding-top: 1px;
  display: inline-block;
  font-size: 17px;
}

/* CAPTCHA ================================================= */
/* Captcha */
.captchaWrapper {
  width: 100%;
  max-width: 520px;
  display: flex;
  align-center: center;
  justify-content: center;
  margin-bottom: 80px;
}

.captchaWrapper img {
  width: 191px;
  height: 50px;
  margin-top: 22px;
  margin-right: 10px;
}

.captchaWrapper .inputWrapper input {
  border: 1px solid #cccccc;
}

@media screen and (max-width: 450px) {
  .captchaWrapper {
    flex-direction: column;
  }
}

/* DATE PICKER ================================================= */
bs-datepicker-container {
  z-index: 1000;
}

bs-datepicker-container .bs-datepicker {
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

bs-datepicker-container .bs-datepicker-head {
  border-radius: 0;
  background-color: #000 !important;
  padding: 5px 10px 6px 10px;
}

bs-datepicker-container .bs-datepicker-head button.next span,
bs-datepicker-container .bs-datepicker-head button.previous span {
  font-size: 28px;
}

bs-datepicker-container bs-datepicker-navigation-view {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

bs-datepicker-container bs-datepicker-navigation-view button {
  background: transparent;
  border: none;
  color: #fff;
  font-family: DaimlerCS-Bold;
  font-weight: 700;
  font-size: 16px;
}

bs-datepicker-container .bs-datepicker-body {
  border: none;
  border-radius: 0;
  padding: 7px 10px 10px 10px;
}

bs-datepicker-container .bs-datepicker-body table.days th,
bs-datepicker-container .bs-datepicker-body table.days td {
  text-align: center;
  width: 36px;
  height: 30px;
  position: relative;
}

bs-datepicker-container .bs-datepicker-body table.days td span,
bs-datepicker-container .bs-datepicker-body table.days td span {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  right: 3px;
  border-radius: 50%;
  z-index: 0;
}

bs-datepicker-container .bs-datepicker-body table.days td span.selected,
bs-datepicker-container .bs-datepicker-body table.days td span.selected:hover {
  background-color: var(--blue-1);
  color: #fff;
}

bs-datepicker-container .bs-datepicker-body table.days td span:hover {
  background-color: #c8c8c8;
}

bs-datepicker-container .bs-datepicker-body table.days td span.is-other-month {
  color: #999;
}

bs-datepicker-container .bs-datepicker-body table.days th:first-child {
  display: none;
}

bs-datepicker-container .bs-datepicker-body table.days td.week {
  display: none;
}

bs-datepicker-container .bs-datepicker-body table.months td {
  width: 89px;
  height: 52px;
  text-align: center;
}

bs-datepicker-container .bs-datepicker-body table.months td:not(:last-child) {
  padding-right: 10px;
}

bs-datepicker-container .bs-datepicker-body table.years td {
  width: 67px;
  height: 52px;
  text-align: center;
}

bs-datepicker-container .bs-datepicker-body table.years td:not(:last-child) {
  padding-right: 10px;
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
}

.dateWrapper .form-control {
  border-radius: 0;
}

.dateWrapper input.form-control:focus {
  border: 1px solid #00677f;
  box-shadow: none;
}

.location-search-hero__filters .dateWrapper {
  margin-bottom: 0;
}

.dateWrapper mat-label {
  position: absolute;
  display: block;
  pointer-events: none;
  left: 10px;
  margin-left: 0;
  top: 36px;
  top: 0;
  transition: 0.3s ease-out all;
  font-size: 19px;
  font-weight: 500;
  color: #666666;
  width: 300px;
  transform-origin: 0 0;
}

.dateWrapper input {
  width: 100%;
  /* border: 1px solid #c8c8c8; */
  /* background: transparent; */
  background: #fff;
  font-size: 18px;
  width: 100%;
  /* margin-top: 24px; */
  left: 0;
  opacity: 1;
  padding: 10px 10px 10px 10px;
}

.dateWrapper input:focus {
  border: 1px solid #00677f;
}

.dateWrapper mat-datepicker-toggle {
  position: absolute;
  top: 24px;
  right: 0px;
}

.dateWrapper .mat-datepicker-toggle-active {
  color: #00677f;
}

.dateWrapper mat-hint {
  font-size: 15px;
  margin-left: 10px;
}

#register .dateWrapper input {
  border: 1px solid #c8c8c8;
  background: #fff;
  font-size: 18px;
  width: 100%;
  padding: 10px;
}

.mdc-button,
.mat-calendar {
  font-family: DaimlerCS, sans-serif;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 44px;
  height: 44px;
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: 0 !important;
}

.mat-calendar-body-cell-content {
  border-radius: 0 !important;
}

.dateWrapper mat-icon {
  position: absolute !important;
  float: none !important;
  top: 35px !important;
  cursor: pointer !important;
  color: #0000008a !important;
  right: 36px !important;
  z-index: 0 !important;
}

/* CUSTOM SWITCH ================================================= */
.switch {
  position: relative;
  display: flex;
  height: 22px;
  font: 16px/1.2 DaimlerCS-Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
}

.switch.gapTop {
  margin-top: 36px;
}

.switch .knob {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 44px;
  transition: 0.2s ease all;
}
.switch .base {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--wb-grey-85);
  z-index: 1;
  width: 40px;
  height: 22px;
  overflow: hidden;
  border-radius: 11px;
}

.switch:hover .base {
  background-color: var(--wb-grey-80);
}

.modal .filters .switch .base {
  background-color: var(--wb-grey-80);
}

.modal .filters .switch:hover .base {
  background-color: var(--wb-grey-75);
}

.switch input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.switch .knob {
  z-index: 2;
}

.switch .knob,
.switch .knob:before {
  transition: 0.2s ease all;
}

.switch .knob:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: #707070;
  border-radius: 50%;
}

.switch input[type="checkbox"]:checked + .knob:before {
  left: 19px;
  background-color: #007a93;
}

.switch input[type="checkbox"]:checked:disabled + .knob:before {
  background-color: #a6cad8;
}

.switch input[type="checkbox"]:disabled + .knob:before {
  background-color: #c8c8c8;
}

.switch .description {
  margin-left: 46px;
  padding-top: 1px;
  display: inline-block;
  font-size: 16px;
}

/* CUSTOM SWITCH MATERIAL ================================================= */
/* .mat-mdc-slide-toggle .mdc-switch {
  width: 40px !important;
  margin-right: 4px;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__icons {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  height: 22px !important;
  width: 40px !important;
  border-radius: 12px !important;
  background-color: #e6e6e6 !important;
}

.mdc-switch__handle::after {
  height: 20px !important;
  width: 20px !important;
  border-radius: 10px !important;
  top: 0px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  height: 20px !important;
  width: 20px !important;
  left: 2px;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--checked .mdc-switch__handle {
  left: auto;
  right: -2px;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
  width: 19px !important;
  left: -1px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before,
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: transparent !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
  display: none;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__shadow {
  display: none;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--unselected:enabled
  .mdc-switch__handle::after {
  background-color: #707070 !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background-color: #007a93 !important;
} */

/* CUSTOM TABS ================================================= */
.customTabs {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  margin-bottom: 40px;
}

.customTabs li {
  flex: 1;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}

.customTabs li:not(:last-child) {
  margin-right: 1px;
}

.customTabs li a {
  flex: 1;
  background-color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 10px;
  text-align: center;
  transition: background-color 0.3s;
}

.customTabs li a:hover {
  background-color: var(--blue-1);
  text-decoration: none;
}

.customTabs li.active a,
.customTabs li.active a:hover {
  background-color: #000;
}

/* Responsive - - - - - - - - - - - - - - - - - - - - - - */
@media screen and (max-width: 600px) {
  .customTabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 40px;
    column-gap: 1px;
    row-gap: 1px;
  }

  .customTabs li {
    margin-right: 0;
  }
}
