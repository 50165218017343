@import "~tippy.js/dist/tippy.css";
/* @import "~tippy.js/themes/light.css"; */
/* @import "~tippy.js/animations/scale.css"; */

/* DAIMLER FINANCIAL TRUCK - SITIO DE DISTRIBUIDORES */
/* ================================================= */
:root {
  --blue-1: #00677f;
  --grey-1: #f4f4f4;

  --wb-blue-05: #00182c;
  --wb-blue-10: #002441;
  --wb-blue-15: #003156;
  --wb-blue-20: #013c6b;
  --wb-blue-25: #014880;
  --wb-blue-30: #025497;
  --wb-blue-35: #0260ab;
  --wb-blue-40: #036dc1;
  --wb-blue-45: #0078d6;
  --wb-blue-50: #008dfc;
  --wb-blue-55: #1998fc;
  --wb-blue-60: #33a4fd;
  --wb-blue-65: #4eaffd;
  --wb-blue-70: #66bbfd;
  --wb-blue-75: #80c6ff;
  --wb-blue-80: #9ad2fe;
  --wb-blue-85: #b4ddfe;
  --wb-blue-90: #cce8ff;
  --wb-blue-95: #e6f5ff;
  --wb-green-05: #051a07;
  --wb-green-10: #07260b;
  --wb-green-15: #0a330f;
  --wb-green-20: #0d4013;
  --wb-green-25: #0f4d16;
  --wb-green-30: #125a1a;
  --wb-green-35: #14661e;
  --wb-green-40: #177321;
  --wb-green-45: #198025;
  --wb-green-50: #21a330;
  --wb-green-55: #37ac45;
  --wb-green-60: #4db559;
  --wb-green-65: #64bf6e;
  --wb-green-70: #7ac883;
  --wb-green-75: #90d198;
  --wb-green-80: #a6daac;
  --wb-green-85: #bce3c1;
  --wb-green-90: #d3edd6;
  --wb-green-95: #e9f6ea;
  --wb-grey-05: #0d0d0d;
  --wb-grey-10: #1a1a1a;
  --wb-grey-15: #262626;
  --wb-grey-20: #333;
  --wb-grey-25: #424242;
  --wb-grey-30: #4f4f4f;
  --wb-grey-35: #5c5c5c;
  --wb-grey-40: #696969;
  --wb-grey-45: #767676;
  --wb-grey-50: #848484;
  --wb-grey-55: #919191;
  --wb-grey-60: #9f9f9f;
  --wb-grey-65: #adadad;
  --wb-grey-70: #bbb;
  --wb-grey-75: #c9c9c9;
  --wb-grey-80: #dbdbdb;
  --wb-grey-85: #e8e8e8;
  --wb-grey-90: #f4f4f4;
  --wb-grey-95: #f8f8f8;
  --wb-red-05: #2b0707;
  --wb-red-10: #410a0a;
  --wb-red-15: #570d0d;
  --wb-red-20: #6d1111;
  --wb-red-25: #821414;
  --wb-red-30: #981717;
  --wb-red-35: #ae1a1a;
  --wb-red-40: #c31e1e;
  --wb-red-45: #d92121;
  --wb-red-50: #ff4a4a;
  --wb-red-55: #ff5c5c;
  --wb-red-60: #ff6e6e;
  --wb-red-65: #ff8080;
  --wb-red-70: #ff9292;
  --wb-red-75: #ffa5a5;
  --wb-red-80: #ffb7b7;
  --wb-red-85: #ffc9c9;
  --wb-red-90: #ffdbdb;
  --wb-red-95: #ffeded;
  --wb-yellow-05: #2e2600;
  --wb-yellow-10: #463900;
  --wb-yellow-15: #5d4c00;
  --wb-yellow-20: #745f00;
  --wb-yellow-25: #8b7100;
  --wb-yellow-30: #a28400;
  --wb-yellow-35: #ba9700;
  --wb-yellow-40: #d1aa00;
  --wb-yellow-45: #e8bd00;
  --wb-yellow-50: #facc00;
  --wb-yellow-55: #fbd11a;
  --wb-yellow-60: #fbd633;
  --wb-yellow-65: #fcdb4c;
  --wb-yellow-70: #fce066;
  --wb-yellow-75: #fde680;
  --wb-yellow-80: #fdeb99;
  --wb-yellow-85: #fef0b3;
  --wb-yellow-90: #fef5cc;
  --wb-yellow-95: #fffae6;
}

* {
  outline: none;
  box-sizing: border-box;
}

h1,
h2,
ul,
p,
figure {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: #ffffff;
  color: #000;
  font: normal 16px/1.2 DaimlerCS-Regular, Arial, "Helvetica Neue", Helvetica,
    sans-serif;
  position: relative;
}

body input {
  font-family: DaimlerCS-Regular, Arial, "Helvetica Neue", Helvetica, sans-serif;
  line-height: 1rem;
}

html {
  color: #000;
  font-family: DaimlerCS, sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.5;
  scroll-behavior: smooth;
}

input,
button,
select {
  -webkit-appearance: none;
  border-radius: 0;
}

:focus {
  outline: 0;
}

a,
a:visited {
  color: #00677f;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.pageWidth {
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 1210px) {
  .pageWidth {
    padding: 0 10px;
    max-width: 100%;
  }
}

h1 {
  font-size: 48px;
  line-height: 52px;
  height: auto;
  display: block;
  margin: 0 0 29px 0;
  font-weight: normal;
}

h3 {
  font-family: "DaimlerCS-Bold";
  font-size: 25px;
  margin: 0 0 15px 0;
}

ul {
  list-style: none;
}

/* ELEMENT-ANGULAR (CUSTOM CONTROLS) ================================================= */
/* .el-select {
  margin-top: 26px;
  width: 100%;
}

.el-select .el-input__inner {
  border: 1px solid #c8c8c8;
  border-radius: 0;
}

.el-select .el-input__inner:focus {
  border: 1px solid #01677f;
}

.el-select .el-select-dropdown {
  margin: 0;
  border-left: 1px solid #01677f;
  border-right: 1px solid #01677f;
  border-bottom: 1px solid #01677f;
  border-top: none;
  border-radius: 0;
  box-shadow: none;
}

.el-select .el-select-dropdown .el-select-dropdown__list {
  padding: 5px;
}

.el-select .el-select-dropdown__item {
  color: #000;
}

.el-select .el-select-dropdown__item:hover {
  background-color: #01677f;
  color: #fff;
} */

/* Fuerza la altura del contenido principal para posicionar el footer al border inferior de la página */
app-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

app-root > .container {
  flex: 1;
}

/* CUSTOM TABLE */
.customTable {
  background-color: #fff;
  color: #000;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5em;
}

.customTable .header,
.customTable .row {
  display: flex;
  position: relative;
}

.customTable .row.disabled {
  color: var(--wb-grey-60);
}

.customTable .header .cell,
.customTable .row .cell {
  padding: 8px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.customTable .header .cell:first-child,
.customTable .row .cell:first-child {
  padding-left: 0px;
}

.customTable .header .cell:last-child,
.customTable .row .cell:last-child {
  padding-right: 0px;
}

.customTable .header {
  border-bottom: 2px solid #cfcfcf;
}

.customTable .row:not(:last-of-type) {
  border-bottom: 1px solid #cfcfcf;
}

.customTable .header {
  font-family: "DaimlerCS-Bold";
}

.customTable .customIcon.confirmation {
  /* color: var(--blue-1); */
  color: var(--wb-green-35);
}

.customTable .customIcon.error {
  color: var(--wb-red-45);
}

.customTable .acciones a:not(:last-child) {
  margin-right: 10px;
}

.customTable .header .cell:last-child {
  position: relative;
}

.customTable .row .cell.acciones {
  display: flex;
}

.customTable .row .cell.acciones a.edit,
.customTable .row .cell.acciones a.delete,
.customTable .row .cell.acciones a.view,
.customTable .row .cell.acciones a.duplicate {
  display: flex;
  width: 24px;
  height: 24px;
  overflow: hidden;
  text-indent: 110%;
  white-space: nowrap;
  background-position: 2px 2px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  transition: all 0.1s;
  margin-right: 4px;
}

.customTable .row .cell.acciones a.edit:hover,
.customTable .row .cell.acciones a.delete:hover,
.customTable .row .cell.acciones a.view:hover,
.customTable .row .cell.acciones a.duplicate:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.customTable .row .cell.acciones a.edit {
  background-image: url("/assets/images/icons/edit.svg");
}

.customTable .row .cell.acciones a.view {
  background-image: url("/assets/images/icons/view.svg");
}

.customTable .row .cell.acciones a.delete {
  background-image: url("/assets/images/icons/delete.svg");
}

.customTable .row .cell.acciones a.duplicate {
  background-image: url("/assets/images/icons/duplicate.svg");
}

.tableActions {
  position: absolute;
  right: 5px;
  top: 10px;
  /* position: relative; */
  display: inline-block;
}

.tableActions .control {
  display: block;
  width: 19px;
  height: 19px;
  /* background-color: #f4f4f4; */
  border-radius: 50%;

  background-image: url("/assets/images/icons/more_actions.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  overflow: hidden;
  white-space: normal;
  text-indent: 110%;
}

.tableActions:hover .control,
.tableActions.active .control {
  background-color: #e5e5e5;
}

.tableActions ul {
  position: absolute;
  right: 0;
  top: calc(100% + 7px);
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 0 10px -2px #0003;
  display: none;
}

/* .tableActions:hover ul, */
.tableActions.active ul {
  display: block;
}

.tableActions ul li {
  font-size: 16px;
}

.tableActions ul li:not(:first-child) {
  border-top: 1px solid #e6e6e6;
}

.tableActions ul li a {
  white-space: nowrap;
  padding: 10px 20px;
  font-family: DaimlerCS-Regular;
  font-size: 18px;
  font-weight: normal;
  color: #000;
  display: block;
}

.tableActions ul li a:hover {
  background-color: var(--wb-grey-90);
  text-decoration: none;
}

/* CUSTOM TABLE MOBILE */
.customTableMobile {
  background-color: #fff;
  color: #000;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5em;
}

.customTableMobile .row {
  /* padding: 8px 20px; */
  padding: 15px 20px;
}

.customTableMobile .row:not(:last-of-type) {
  /* border-bottom: 1px solid #cfcfcf; */
  border-bottom: 2px solid #cfcfcf;
}

.customTableMobile .row .wrapper2G {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.customTableMobile .row .wrapper2G.vertical {
  flex-direction: column;
  gap: 5px;
}

.customTableMobile .row .wrapper2G .nombre {
  padding-right: 10px;
}

.customTableMobile .row .wrapper2G > div:nth-child(2) {
  text-align: right;
}

.customTableMobile .row .wrapper2G.vertical > div:nth-child(2) {
  text-align: left;
}

.customTableMobile .row .wrapper2G:not(:last-of-type) {
  border-bottom: 1px solid #cfcfcf;
}

.customTableMobile .row .wrapper2G.acciones {
  border-bottom: none !important;
  margin-bottom: 5px;
}

.customTableMobile .row .checkboxWrapper .description {
  font-size: 16px;
  padding-top: 0;
}

/* DIVIDER */
.dividerLine {
  border-bottom: 4px solid #fff;
}

.customTable .customIcon {
  speak: never;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-family: daimler_iconset;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  width: 1em;
  color: #000;
}

.customTable a .customIcon {
  color: var(--blue-1);
}

.customTable a:hover .customIcon {
  color: var(--wb-blue-45);
}

/* MODAL */
.modalWrapper {
  width: 100%;
  max-width: 840px;
}

.modal {
  background-color: #fff;
  padding: 50px 40px 50px 40px;
  position: relative;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

@media screen and (max-width: 500px) {
  .modal {
    padding: 50px 20px;
  }
}

.modal .close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #000;
  display: block;
  width: 32px;
  height: 32px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;

  display: block;
  overflow: hidden;
  text-indent: 110%;

  background-image: url("../images/icons/close.svg");

  background-size: 18px 18px;
  background-position: center center;
  background-repeat: no-repeat;
}

.modal .close:hover {
  background-color: #707070;
}

.modal h3 {
  font-family: DaimlerCS-Bold;
  font-size: 32px;
  margin: 0 0 1em 0;
}

.modal a.close span {
  display: none;
}

.modal h1 {
  font-size: 28px;
  color: #666666;
  margin-bottom: 0.6em;
}

.modal p {
  font-size: 19px;
  color: #666666;
  margin-bottom: 0.6em;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
}

.modal .modalContent {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.modal .recipientsWrapper {
  margin-bottom: 10px;
}

.modal .modalActions {
  padding: 30px 0 0 0;
  display: flex;
}

.modal .modalActions button:not(:last-child) {
  margin-right: 30px;
}

.gapSm {
  height: 20px;
}

/* Lineas auxiliares */
.profileGuide {
  position: relative;
  display: flex;
}

.profileGuide span {
  color: #fff;
  background-color: var(--wb-grey-35);
  font-size: 20px;
  z-index: 1000;
  padding: 0 8px 2px 8px;
}

.profileGuide.distribuidor {
  background-color: rgb(171, 197, 229);
}

.profileGuide.distribuidor span {
  background-color: rgb(0, 115, 255);
}

.profileGuide.admin {
  background-color: #a5ddb0;
}

.profileGuide.admin span {
  background-color: var(--wb-green-55);
}

.hGuide {
  border-top: 2px dashed rgb(255, 0, 212);
  width: 100%;
  position: relative;
  background-color: rgb(203, 149, 194);
}

.hGuide span {
  background-color: rgb(255, 0, 212);
  color: #fff;
  padding: 0 4px;
}

.hsGuide {
  border-top: 1px dashed rgb(0, 115, 255);
  width: 100%;
  position: relative;
  background-color: rgb(171, 197, 229);
}

.hsGuide span {
  background-color: rgb(0, 115, 255);
  color: #fff;
  padding: 0 4px;
}

.hsGuide.admin {
  border-top: 1px dashed var(--wb-green-55);
  background-color: #a5ddb0;
}

.hsGuide.admin span {
  background-color: var(--wb-green-55);
}

.mobileWrapper {
  max-width: 300px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.sendWrapper {
  display: flex;
  align-items: baseline;
}

.buttonWrapper {
  margin-top: 24px;
}

button.primary,
button.secondary {
  font-family: "DaimlerCS-Bold";
  font-weight: bold;
  font-size: 16px;
  padding: 9px 17px 11px 17px;
  text-align: center;
  min-width: 140px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  margin-bottom: 0;
  margin-right: 0;
}

button.primary {
  background: black;
  border: solid 1px #000;
  color: #fff;
}

button.primary:hover {
  background-color: #707070;
  border: solid 1px #707070;
  cursor: pointer;
}

button.primary:disabled,
button.primary:disabled:hover {
  background: #e6e6e6;
  border: solid 1px #e6e6e6;
  color: #707070;
}

button.secondary {
  color: #000;
  background: #e6e6e6;
  border: solid 1px #000;
}

button.secondary:hover {
  background: #fff;
  border: solid 1px #000;
}

button.secondary:disabled,
button.secondary:disabled:hover {
  background: #fff;
  border: solid 1px #c8c8c8;
  color: #c8c8c8;
}

button.tertiary {
  background: #e6e6e6;
  border: none;
  color: #000;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1.5em;
  font-family: "DaimlerCS-Bold", sans-serif;
  padding: 0.1rem 0.5rem 0.2rem 0.4rem;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

button.tertiary:hover {
  background: #c8c8c8;
}

button.tertiary:disabled,
button.tertiary:disabled:hover {
  color: #707070;
}

button.tertiary:disabled:hover {
  background: #e6e6e6;
}

.wrapperG4 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 10px;
  margin-bottom: 40px;
}

.wrapperG5 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 10px;
  margin-bottom: 40px;
}

.location-search-hero__caption .wrapperG4,
.location-search-hero__caption .wrapperG3 {
  margin-bottom: 0;
  padding-bottom: 0;
}

.location-search-hero__caption .wrapperG4 label,
.location-search-hero__caption .wrapperG3 label {
  font-size: 17px;
  font-weight: 500;
  color: #666666;
  width: 300px;
  margin-left: 10px;
  margin-bottom: 5px;
  display: block;
}

.location-search-hero__caption .wrapperG3 .dateAndCheck label {
  width: auto;
}

/* Responsive - - - - - - - - - - - - - - - - - - - - - - */
@media screen and (max-width: 1000px) {
  .wrapperG5 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 990px) {
  .location-search-hero__caption .wrapperG4,
  .location-search-hero__caption .wrapperG3 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media screen and (max-width: 700px) {
  .wrapperG5,
  .wrapperG4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 400px) {
  .wrapperG5,
  .wrapperG4 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.wrapperG3 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 10px;
  padding-bottom: 40px;
}

.wrapperG2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 10px;
}

.wrapperG3 h3 {
  font-family: "DaimlerCS-Bold";
  font-size: 25px;
}

.wrapperG3 .actions button,
.modalActions button {
  background: #000;
  border: 1px solid #000;
  color: white;
  font-size: 15px;
  margin: 0 0 0 0;
  padding: 12px 30px;
  text-align: center;
  transition: all 0.2s;
  min-width: 120px;
}

.wrapperG3 .actions button.secondary,
.modalActions button.secondary {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  font-size: 15px;
  margin: 0 0 0 0;
  padding: 12px 30px;
  text-align: center;
  transition: all 0.2s;
  min-width: 120px;
}

.wrapperG3 .actions button:disabled,
.wrapperG3 .actions button:disabled:hover,
.modalActions button:disabled,
.modalActions button:disabled:hover {
  background: var(--wb-grey-65);
  border: 1px solid var(--wb-grey-65);
  cursor: default;
  color: var(--wb-grey-85);
}

.wrapperG3 .actions button:hover,
.modalActions button:hover {
  background-color: #00677f;
  border: 1px solid #00677f;
  color: #ffffff;
  cursor: pointer;
}

/* Responsive - - - - - - - - - - - - - - - - - - - - - - */
@media screen and (max-width: 700px) {
  .wrapperG3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 550px) {
  .wrapperG3 {
    grid-template-columns: repeat(1, 1fr);
  }
  .wrapperG2 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.wrapperG3.actionsAndPager {
  padding-bottom: 60px;
  align-items: center;
}

.wrapperG3.actionsAndPager :nth-child(1) {
  display: flex;
  justify-content: flex-start;
}

.wrapperG3.actionsAndPager :nth-child(2) {
  display: flex;
  justify-content: center;
}

.wrapperG3.actionsAndPager :nth-child(3) {
  display: flex;
  justify-content: flex-end;
}

.wrapperG3.actionsAndPager .actions {
  display: flex;
}

.wrapperG3.actionsAndPager .actions a {
  background-color: #a6a6a6;
  transition: all 0.3s;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  text-decoration: none;
}

.wrapperG3.actionsAndPager .actions a:last-child {
  margin-right: 0;
}

.wrapperG3.actionsAndPager .actions a i {
  color: #fff;
  font-size: 18px;
}

.wrapperG3.actionsAndPager .actions a:hover {
  background-color: #666;
}

/* Back nav */
.backNav {
  margin-bottom: 5px;
  font-size: 16px;
  display: flex;
}

.backNav a {
  color: var(--blue-1);
  font-family: DaimlerCS-Demi, Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.backNav a i {
  margin-right: 3px;
  font-size: 12px;
}

.backNav a:hover {
  text-decoration: underline;
}

.backNav a:hover i {
  text-decoration: none;
}

/* INTRO ================================= */
#intro {
  max-width: 2200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  min-height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#intro figure {
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#intro figure picture {
  display: flex;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#intro img {
  object-fit: cover;
  width: 100%;
}

#intro figure figcaption {
  padding: 40px 10px 50px 50px;
  background-color: #fff;
  position: absolute;
  left: 40%;
  right: 0;
  text-align: right;
  bottom: 0;
  transform: translate(0, 50%);
  z-index: 90;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#intro figure figcaption h1 {
  font-size: clamp(1.7rem, 4.5vw, 2.3rem);
  font-family: "DaimlerCS-Bold";
  margin: 0;
  padding: 0;
}

#intro figure figcaption .area {
  font-size: 25px;
  font-family: "DaimlerCS-Bold";
  margin: 0;
  padding: 0;
}

#intro .filters {
  /* margin-bottom: -1px; */
}

#intro .filters .container {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-blend-mode: overlay;
  background-color: #fff;
  background-color: hsla(0, 0%, 100%, 0.8);
  max-width: 1300px;
  margin: 0 auto;
}

#intro .location-search-hero__caption {
  margin: 0 auto;
  max-width: 1190px;
  padding: 2rem 0;
  z-index: 10;
}

#intro .location-search-hero__filters {
  margin-top: 10px;
}

#intro .title--bold {
  font-size: clamp(1.7rem, 4.5vw, 2.3rem);
  font-family: DaimlerCS-Bold;
  margin: 0 0 5px 0;
}

#intro .area {
  font-size: 25px;
  font-family: DaimlerCS-Bold;
  margin: 0 0 5px 0;
  display: inline-block;
  padding: 0;
}

#intro .detalle {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

#intro .detalle h2 {
  font-family: DaimlerCS-Bold;
  font-size: clamp(1.2rem, 3.5vw, 1.7rem);
  margin-bottom: 0.2em;
  margin-right: 0.4em;
}

#intro .detalle .fecha {
  font-family: DaimlerCS-Regular;
  white-space: nowrap;
  font-size: 16px;
  font-weight: normal;
}

#intro .location-search-hero__filters .dateWrapper .mat-icon {
  position: absolute;
  float: none;
  right: 35px;
  top: 34px;
}

@media screen and (max-width: 1210px) {
  #intro .location-search-hero__caption {
    padding: 2rem 10px;
    max-width: 100%;
  }
}

#intro .filtersActions {
  margin-top: 20px;
}

#intro .filtersActions button {
  background: black;
  border: solid 1px #000;
  color: #fff;
  font-family: "DaimlerCS-Bold";
  font-weight: bold;
  font-size: 16px;
  padding: 9px 17px 11px 17px;
  text-align: center;
  /* margin-bottom: 18px; */
  min-width: 140px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

#intro .filtersActions button:disabled,
#intro .filtersActions button:disabled:hover {
  background: #e6e6e6;
  border: solid 1px #e6e6e6;
  color: #707070;
}

#intro .filtersActions button:hover {
  background-color: #707070;
  border: solid 1px #707070;
}

#intro .filtersActions button.secondary {
  color: #000;
  background: #e6e6e6;
  border: solid 1px #000;
}

#intro .filtersActions button.secondary:disabled,
#intro .filtersActions button.secondary:disabled:hover {
  color: #c8c8c8;
  background: #e6e6e6;
  border: solid 1px #c8c8c8;
}

#intro .filtersActions button.secondary:hover {
  background: #fff;
  border: solid 1px #000;
}

#intro .filtersActions button:not(:last-child) {
  margin-right: 30px;
}

#intro .dateWrapper input {
  border: 1px solid #fff;
}

/* Responsive - - - - - - - - - - - - - - - - - - - - - - */

@media screen and (max-width: 1000px) {
  #intro {
    /* height: 680px; */
  }
}

@media screen and (max-width: 990px) {
  #intro figure figcaption {
    padding: 20px 10px 30px 10px;
    position: relative;
    left: 0;
    text-align: left;
    transform: translate(0, 0);
    z-index: 90;
    border-bottom: 1px solid #c8c8c8;
  }
}

/* Detalle */
#detail {
  background-color: #fff;
  width: 100%;
  max-width: 2200px;
  margin: 0 auto;
  padding-top: 3rem;
  position: relative;
  padding-bottom: 60px;
}

/* Título de sección */
.sectionTitle {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  flex-wrap: wrap;
}

.sectionTitle h2 {
  font-size: 36px;
  font-family: "DaimlerCS-Bold";
  font-weight: bold;
  margin-right: 60px;
}

.sectionTitle h3 {
  font-size: 25px;
  font-family: "DaimlerCS-Bold";
  font-weight: bold;
  margin-bottom: 40px;
}

.sectionTitle a.refresh {
  font-family: "daimler_iconset";
  width: 50px;
  height: 50px;
  background: black;
  border: 0px;
  color: white;
  font-size: 24px;
  padding: 0px;
  text-align: center;
  transition: all 0.2s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionTitle a.refresh:hover {
  background-color: #00677f;
  color: #ffffff;
  cursor: pointer;
}

/* Actions wrapper */
.actionsWrapper {
  display: flex;
}

.actionsWrapper button:not(:last-child),
.actionsWrapper app-button:not(:last-child) button {
  margin-right: 20px;
}

/* Acordión grande */
.bigAccordion .title {
  background-color: #000;
  color: #fff;
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  border-top: 4px solid #fff;
}

.bigAccordion .title h3 {
  font-size: 21px;
  font-family: "DaimlerCS-Light";
  margin: 0;
}

.bigAccordion .toggle {
  font-family: "daimler_iconset";
  width: 32px;
  height: 32px;
  background: black;
  border: 0px;
  color: white;
  font-size: 24px;
  padding: 0px;
  text-align: center;
  transition: all 0.2s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigAccordion .toggle span.plus {
  display: block;
}

.bigAccordion .toggle span.minus {
  display: none;
}

.bigAccordion .toggle.expanded span.plus {
  display: none;
}

.bigAccordion .toggle.expanded span.minus {
  display: block;
}

.bigAccordion .wrapper {
  padding: 30px 20px;
  z-index: 1000;
  overflow: hidden;
  max-height: 0;
  transition: all 0.6s ease-out;
}

.bigAccordion .wrapper.expanded {
  max-height: 2500px;
  overflow: auto;
}

/* Botón para envío de formularios */
.sendWrapper input {
  min-width: 140px;
  height: 44px;
  background: black;
  border: 0px;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  margin: 0;
  padding: 0px 15px;
  text-align: center;
  transition: all 0.2s;
}

.sendWrapper input:hover {
  background-color: #00677f;
  color: #ffffff;
  cursor: pointer;
}

.wrapperG3 .sendWrapper,
.wrapperG4 .sendWrapper {
  padding-top: 24px;
}

/* NO HAY INFORMACIÓN PARA MOSTRAR */
.noData {
  background-color: #f2f2f2;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1.1em;
  margin-bottom: 30px;
  padding: 20px;
}

/* INDICADORES */
.indicators {
  display: grid;
  margin-bottom: 70px;
  column-gap: 3px;
  row-gap: 3px;
  font-size: 16px;
  line-height: 1.6em;
}

.indicators > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d3d3d5;
  padding: 17px 10px;
}

.indicators > div.total {
  background-color: #c0c0c2;
}

.indicators.reducciones {
  grid-template-columns: repeat(6, 1fr);
}

.indicators.circulares {
  grid-template-columns: repeat(3, 1fr);
}

/* Responsive - - - - - - - - - - - - - - - - - - - - - - */
@media screen and (max-width: 970px) {
  .indicators.reducciones {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 560px) {
  .indicators.reducciones {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Tabla Detalle de Circulares de Marketing ================================= */
.customTable.circularesDetalle .cell:first-child {
  padding-left: 20px;
}

.customTable.circularesDetalle .cell:last-child {
  padding-right: 20px;
}

.customTable.circularesDetalle > div {
  white-space: nowrap;
}

.customTable.circularesDetalle .nombre {
  flex: 1;
}

.customTable.circularesDetalle .perfil {
  width: 22%;
}

.customTable.circularesDetalle .distribuidor {
  width: 35%;
}

.customTable.circularesDetalle .fecha {
  width: 17%;
}

/* CUSTOM GRID */
.customGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  margin-bottom: 120px;
}

.customGrid .card {
  margin: 0 !important;
}

.customGrid .card--square .card__inner {
  align-items: center;
}

.customGrid .card--headline-teaser .card__inner {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  grid-template-columns: repeat(1, 1fr);
}

.customGrid .card--headline-teaser .card__inner .card__picture {
  height: 50%;
}

.customGrid .card .title--5 {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
}

.customGrid .card .cta {
  font-size: 0.8rem;
  margin-top: 20px;
  display: block;
}

.customGrid .card:hover .card__caption span.cta {
  transform: none;
}

/* Responsive - - - - - - - - - - - - - - - - - - - - - - */
@media screen and (max-width: 992px) {
  .customGrid {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 30px;
  }
}

/* PRELOADER */

#basic_preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

#basic_preloader_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #c8c8c8;
  opacity: 0.8;
}

#lbMain.lbLoader {
  background-color: #d3d3d3;
}

#basic_preloader .basic_lbLoading {
  position: relative;
  top: calc(50% - 32px);
  left: calc(50% - 16px);
  z-index: 10000;
}

.basic_lbLoading {
  width: 33px;
  height: 8px;
  font-size: 8px;
}

.basic_lbLoading > div {
  height: 100%;
  width: 100%;
  display: block;
  margin-bottom: 0.6em;
  animation: stretchdelay 1.56s infinite ease-in-out;
  -o-animation: stretchdelay 1.56s infinite ease-in-out;
  -ms-animation: stretchdelay 1.56s infinite ease-in-out;
  -webkit-animation: stretchdelay 1.56s infinite ease-in-out;
  -moz-animation: stretchdelay 1.56s infinite ease-in-out;
}

.basic_lbLoading .cssload-rect2 {
  animation-delay: -1.3s;
  -o-animation-delay: -1.3s;
  -ms-animation-delay: -1.3s;
  -webkit-animation-delay: -1.3s;
  -moz-animation-delay: -1.3s;
}

.basic_lbLoading .cssload-rect3 {
  animation-delay: -1.04s;
  -o-animation-delay: -1.04s;
  -ms-animation-delay: -1.04s;
  -webkit-animation-delay: -1.04s;
  -moz-animation-delay: -1.04s;
}

body .joyride-modal-bg {
  background-color: #c8c8c8;
  opacity: 0.8;
}

@keyframes stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleX(0.8);
    background-color: rgba(200, 200, 200, 0.98);
    box-shadow: 0 0 0 rgba(10, 10, 10, 0.03);
  }

  20% {
    transform: scaleX(1);
    background-color: rgba(0, 103, 127, 0.94);
    box-shadow: 0 4px 5px rgba(10, 10, 10, 0);
  }
}

/* CUSTOM PAGINATOR*/
.customPaginator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.customPaginator .total {
  padding: 0.2rem 0;
}
.customPaginator .total,
.customPaginator .auxiliar {
  width: 300px;
  display: flex;
  align-items: center;
}

.customPaginator .auxiliar {
  justify-content: flex-end;
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pagination ul {
  display: flex;
}

.pagination .page-item {
  display: inline-flex;
}

.pagination .page-link {
  color: #000;
  padding: 0.2rem 0.5rem;
  text-align: center;
  transition: all 0.2s;
  text-decoration: none;
}

.pagination .page-item.active .page-link {
  background-color: #000000;
  color: white;
}

.pagination .page-item.disabled .page-link {
  color: #aaa;
}
.pagination .page-item.disabled .page-link:hover {
  color: #aaa;
  background-color: transparent;
}

.pagination .page-link:hover {
  background-color: #e6e6e6;
}

.pagination .page-item.disabled .page-link[aria-label="First"] span {
  content: "<";
}

.auxiliar {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.page-size-label {
  margin-right: 8px;
}

.page-size-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('data:image/svg+xml;utf8,<svg fill="none" stroke="%23333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M6 9l6 6 6-6"></path></svg>')
    no-repeat right 10px center;
  background-size: 12px 12px;
  padding: 8px 24px 8px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.page-size-select:focus {
  border-color: #007bff;
  outline: none;
}

.customPaginator .pagination .page-item .page-link {
  position: relative;
}

.customPaginator .pagination .page-item:first-child .page-link::after {
  content: "Primera página";
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  display: none;
}

.customPaginator .pagination .page-item:nth-child(2) .page-link::after {
  content: "Página anterior";
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  display: none;
}

.customPaginator .pagination .page-item:nth-last-child(2) .page-link::after {
  content: "Siguiente página";
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  display: none;
}

.customPaginator .pagination .page-item:last-child .page-link::after {
  content: "Última página";
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  display: none;
}

.customPaginator .pagination .page-item .page-link:hover::after {
  display: block;
}

/* .customPaginator {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination ul {
  display: flex;
  background-color: greenyellow;
}

.customPaginator .total {
  width: 20%;
}

.customPaginator .resultsPerPage {
  width: 65%;
}

.mat-mdc-paginator-outer-container {
  width: 100%;
}

.mat-mdc-paginator-container {
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}

.paginator-bg .mat-mdc-select-trigger {
  background: #e6e6e6 !important;
}

.paginator-bg .mat-mdc-select-value {
  background: #e6e6e6 !important;
} */

/* Tabs ==================================================== */
.tabs {
  display: grid;
  background-color: #f2f2f2;

  border-bottom: 1px solid #bfbfbf;
}

.module > .tabs:first-child {
  margin-top: 10px;
}

.tabs.w2 {
  grid-template-columns: repeat(2, 1fr);
}

.tabs.w3 {
  grid-template-columns: repeat(3, 1fr);
}

.tabs.w4 {
  grid-template-columns: repeat(4, 1fr);
}

.tabs a {
  text-align: center;
  font-family: "DaimlerCS-Bold";
  font-size: 20px;
  font-weight: bold;
  color: #858585;
  position: relative;
  display: block;
  transition: all 0.2s;
  padding: 0.3em 0;
}

.tabs a.active {
  color: #000;
}

.tabs a.active:after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--blue-1);
}

.tabs a:hover {
  text-decoration: none;
}

.tabs a:not(.active):hover {
  background-color: #e6e6e6;
}
