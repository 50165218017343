@import '~tippy.js/dist/tippy.css';
// @import '~tippy.js/themes/light.css';
// @import '~tippy.js/animations/scale.css';

.tippy-content {
  position: relative;
}

.tippy-close {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 9px;
  right: 9px;
  fill: rgb(158, 160, 165);
  cursor: pointer;
  z-index: 1;
}

.tippy-box {
  border-radius: 0;
  background-color: #000;
  font-size: 11px;

  .tippy-content {
    padding: 5px 8px;
    font-size: 14px;
    font-family: "DaimlerCS-Demi";
    font-weight: 500;
  }
}

.tippy-box::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
  margin-left: -5px;
  top: 100%;
  left: 50%;
  border-color: #000 transparent transparent transparent;
}

.tippy-box[data-theme~='light'] {
  font-size: 12px;
  word-break: break-word;
  border-radius: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
  color: rgb(79, 80, 83);

  .tippy-content {
    padding: 13px 48px 13px 20px;
  }
}

.tippy-arrow::before {
  box-shadow: -4px 4px 14px -4px rgba(0, 0, 0, 0.2);
}