/* DaimlerCS-Regular ================================================= */
@font-face {
  font-family: "DaimlerCS-Regular";
  src: url("/assets/webfonts/DaimlerCS-Regular.eot");
  src: url("/assets/webfonts/DaimlerCS-Regular.eot?#iefix") format("embedded-opentype"),
    url("/assets/webfonts/DaimlerCS-Regular.woff2") format("woff2"),
    url("/assets/webfonts/DaimlerCS-Regular.woff") format("woff"),
    url("/assets/webfonts/DaimlerCS-Regular.ttf") format("truetype"),
    url("/assets/webfonts/DaimlerCS-Regular.svg#DaimlerCS-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* DaimlerCS-Bold ================================================= */
@font-face {
  font-family: "DaimlerCS-Bold";
  src: url("/assets/webfonts/DaimlerCS-Bold.eot");
  src: url("/assets/webfonts/DaimlerCS-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/webfonts/DaimlerCS-Bold.woff2") format("woff2"),
    url("/assets/webfonts/DaimlerCS-Bold.woff") format("woff"),
    url("/assets/webfonts/DaimlerCS-Bold.ttf") format("truetype"),
    url("/assets/webfonts/DaimlerCS-Bold.svg#DaimlerCS-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

/* DaimlerCS-Demi ================================================= */
@font-face {
  font-family: "DaimlerCS-Demi";
  src: url("/assets/webfonts/aimlerCS-Demi.eot");
  src: url("/assets/webfonts/DaimlerCS-Demi.eot?#iefix") format("embedded-opentype"),
    url("/assets/webfonts/DaimlerCS-Demi.woff2") format("woff2"),
    url("/assets/webfonts/DaimlerCS-Demi.woff") format("woff"),
    url("/assets/webfonts/DaimlerCS-Demi.ttf") format("truetype"),
    url("/assets/webfonts/DaimlerCS-Demi.svg#DaimlerCS-Demi") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* DaimlerCA-Regular ================================================= */
@font-face {
  font-family: "DaimlerCA-Regular";
  src: url("/assets/webfonts/DaimlerCA-Regular.eot");
  src: url("/assets/webfonts/DaimlerCA-Regular.eot?#iefix") format("embedded-opentype"),
    url("/assets/webfonts/DaimlerCA-Regular.woff2") format("woff2"),
    url("/assets/webfonts/DaimlerCA-Regular.woff") format("woff"),
    url("/assets/webfonts/DaimlerCA-Regular.ttf") format("truetype"),
    url("/assets/webfonts/DaimlerCA-Regular.svg#DaimlerCA-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* DaimlerCS-Light ================================================= */
@font-face {
  font-family: "DaimlerCS-Light";
  src: url("/assets/webfonts/DaimlerCS-Light.eot");
  src: url("/assets/webfonts/DaimlerCS-Light.eot?#iefix") format("embedded-opentype"),
    url("/assets/webfonts/DaimlerCS-Light.woff2") format("woff2"),
    url("/assets/webfonts/DaimlerCS-Light.woff") format("woff"),
    url("/assets/webfonts/DaimlerCS-Light.ttf") format("truetype"),
    url("/assets/webfonts/DaimlerCS-Light.svg#DaimlerCS-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Daimler Iconset ================================================= */
@font-face {
  font-family: "daimler_iconset";
  src: url("/assets/webfonts/daimler_iconset.eot");
  src: url("/assets/webfonts/daimler_iconset.eot") format("embedded-opentype"),
    url("/assets/webfonts/daimler_iconset.woff2") format("woff2"),
    url("/assets/webfonts/daimler_iconset.woff") format("woff"),
    url("/assets/webfonts/daimler_iconset.ttf") format("truetype"),
    url("/assets/webfonts/daimler_iconset.svg#daimler_iconset") format("svg");
  font-weight: normal;
  font-style: normal;
}